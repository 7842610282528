<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="letters__hero">
					<h1>Robert <br> Druskin</h1>
					<p>A Message from the Non-Executive Chairman</p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="letters__body" >
					<blockquote>Over the course of this very unusual year, DTCC once again stepped up to help protect the financial system as the COVID-19 pandemic sparked significant market volumes and instability. While the Board and senior management have always enjoyed a strong working relationship, we partnered even more closely in 2020 to address the myriad issues related to the crisis, providing strategic counsel, serving as a sounding board and offering real-time information as events unfolded quickly. Despite the complexity of the challenges facing the firm and its clients, Mike Bodson and his senior leadership team deftly navigated the uncertainty, delivering an outstanding performance in all key areas.</blockquote>

					<p>The true test of a company and its leadership is how they respond during difficult moments. As Hall of Fame baseball player Willie Mays once said, “It isn’t hard to be good from time to time in sports. What is tough, is being good every day.” That maxim applies equally in business, and it speaks directly to DTCC’s mission. Unlike other companies, DTCC doesn’t have the luxury of a bad week or subpar year. Every day, the firm must be on top of its game, clearing and settling all trading activity – a responsibility that became magnified last year as the world faced the pandemic crisis. </p>

					<p>As the COVID-19 virus spread from Asia to all parts of the world, markets experienced massive swings as investor sentiment shifted from optimism to fear. DTCC has a long and impressive history of excelling when the financial system is under intense stress, and management, with input and support from the Board, developed a comprehensive crisis response plan that achieved the twin goals of protecting the safety of our colleagues while continuing to serve the industry. </p>

					<p>The Board and management have a highly collaborative relationship, where sharing information, debating ideas and challenging one another produce consistently strong results. This dialogue serves another important purpose by ensuring consistent alignment between the industry’s top needs and DTCC’s strategic direction. While management, with the Board’s oversight, devoted a significant amount of time to assessing all the issues related to the crisis, the firm still made very good progress advancing its key objectives and, in some cases, proactively adjusting course to meet new or evolving client needs. Reinforcing a point I made in last year’s letter, having a Board comprised of practitioners and others who have a deep understanding of the financial system and post-trade processing allows DTCC to stay attuned to subtle shifts in the market and reinforces the value of its ownership and governance structure.  </p>

					<p>The firm’s strong performance in 2020 was enabled by its ongoing efforts to embed agile principles across many of the business and support functions, building a deep bench of diverse talent and empowering teams to make faster decisions and bring  products and services to market quickly. The Board is confident that DTCC is well positioned to continue driving positive change in the marketplace and address the top priorities of its clients, including how the pandemic might prompt a shift in business needs.</p>

					<p>As we move forward from a very difficult year, I want to express my appreciation to the Board for their invaluable contributions to DTCC. They fulfilled their duties with energy and enthusiasm and provided counsel on critical issues facing the firm.  We say goodbye and thank you to two outstanding colleagues and friends, Emily Portney and Michael Herskovitz, who left the Board in 2020 after years of dedicated service. With change comes opportunity as we welcome Debbie Cunningham, Craig Messinger and Andrea Pfenning to the Board. I have no doubt they will continue in the fine tradition of their fellow Board members. To Mike, the senior management team and all DTCC employees, the Board and I offer you our sincerest appreciation for everything you did in 2020 to keep the firm operating at peak form.  You have proven once again that we can accomplish great things when we all work together. </p>

					<p>With the strong support and ongoing partnership of all stakeholders, DTCC enters 2021 on an upward trajectory and ready to lead transformation as we enter a new era.</p>

					<p>
						<img src="~@/assets/letters/druskinsig@2x.jpg" width="298" /><br>
						<strong>Bob Druskin</strong><br>
						<em>Non-Executive Chairman of the Board</em>
					</p>
				</div>
			</template>

			<template v-slot:footer>
				<article-next class="letters__next" :image="'letters/bgSusan@2x.jpg'" :link="{params: {letter: 'susan'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>OUR LEADERSHIP</template>
					<template v-slot:title>Susan Cosgrove</template>
				</article-next>
			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'

export default {
	name: 'Robert',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
	},
}
</script>

<style scoped lang="scss">

.letters {

	&__hero {
		background-image: url('~@/assets/letters/bg-robert@2x.jpg');
	}
}

</style>
